import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Web3 from "web3";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

export default function PurchaseDialog(props) {

    const defaultTicketsToMint = 1;

    const [ticketsToMint, setTicketsToMint] = React.useState(defaultTicketsToMint);
    const [totalPrice, setTotalPrice] = React.useState(getTotalPriceText(defaultTicketsToMint));

    const [error, setError] = React.useState('');

    let disabledButton = false;
    let minValue = 1;

    function toEther(value) {
        const web3 = new Web3(window.ethereum);
        return web3.utils.fromWei(value.toString(), 'ether');
    }

    function getTotalPriceText(value) {
        let result = '';
        if (!props.lottery) {
            return result;
        }
        const costPerTicket = toEther(props.lottery.base.costPerTicket * value);
        return result + costPerTicket + ` ${props.tokenname}`
    }

    function onSliderChanged(value) {
        setTicketsToMint(value);
        setTotalPrice(getTotalPriceText(value));
    }

    async function _mintLotteryTickets(ticketsToMint) {
        try {
            const lotteryId = props.lottery.base.lotteryId.toString();
            const mintValue = props.lottery.base.costPerTicket.mul(ticketsToMint);
            await props.contract.contract.mintTickets(lotteryId, {value: mintValue});
            props.onHide();
        } catch (error) {
            console.log(error);

            const ERROR_CODE_TX_REJECTED_BY_USER = 4001;
            if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                setError('Transaction canceled by user');
            } else {
                let message = `Error: ${error.code}`;
                // if (error.data) {
                //     message += ': ' + error.data.message;
                // }
                setError(message);
            }
        }
    };

    function mintTickets() {
        console.log("mintTickets: " + ticketsToMint);
        _mintLotteryTickets(ticketsToMint);
    }

    if (!props.lottery) {
        return null;
    }

    function onModalShown() {
        setError('')
        if (availableTicketsFromBalance === 0) {
            minValue = 0
            setTicketsToMint(0)
            setTotalPrice((getTotalPriceText(0)))
            setError('Insufficient funds')
        } else {
            const ttm = Math.floor(ticketsCount / 2) > 1 ? Math.floor(ticketsCount / 2) : 1
            setTicketsToMint(ttm);
            setTotalPrice(getTotalPriceText(ttm));
        }
    }

    function changeValueAndText(value) {
        setTicketsToMint(value)
        setTotalPrice((getTotalPriceText(value)))
    }

    const lotteryId = props.lottery.base.lotteryId.toString();
    const maxNumberOfTickets = props.lottery.base.maxNumberOfTickets.toNumber();
    const numberOfTicketsMinted = props.lottery.numberOfTicketsMinted.toNumber();
    let ticketsCount = maxNumberOfTickets - numberOfTicketsMinted;
    const availableTicketsFromBalance = Math.floor(toEther(props.balance) / toEther(props.lottery.base.costPerTicket))
    if (availableTicketsFromBalance < ticketsCount) {
        ticketsCount = availableTicketsFromBalance
    }

    if (availableTicketsFromBalance === 0) {
        disabledButton = true

    }


    function isLotteryEnded(lottery) {
        const endTime = lottery.base.endingTimestamp.toNumber();
        const now = Math.floor(Date.now() / 1000);
        const seconds = Math.max(endTime - now, 0);
        return seconds <= 3
    }

    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Box #{lotteryId}
                </Modal.Title>
            </Modal.Header>
            <div className="modal-inner">
                <Notifier onShown={onModalShown}/>
                <p className="modal-info-label">Tickets to mint</p>
                <div className="modal-info-input">
                    <p>{ticketsToMint}</p>
                    <span onClick={() => changeValueAndText(ticketsCount)}>{ticketsCount} max</span>
                </div>
                <div className="modal-info-slider">
                    <Slider
                        key={lotteryId}
                        defaultValue={defaultTicketsToMint}
                        value={ticketsToMint}
                        onChange={(value) => {
                            onSliderChanged(value)
                        }}
                        min={minValue}
                        max={ticketsCount}
                        disabled={disabledButton}
                        trackStyle={{
                            background: 'linear-gradient(270deg, #C286FF 2.34%, #E1C3FF 100%)',
                            height: 9
                        }}
                        handleStyle={{
                            border: '4px solid #FFF',
                            borderWidth: '4',
                            height: 32,
                            width: 32,
                            marginLeft: 0,
                            marginTop: -12,
                            opacity: '1',
                            backgroundColor: '#C286FF',
                            boxShadow: '0px 2px 8.4px 0px rgba(69, 32, 107, 0.22)'
                        }}
                        railStyle={{backgroundColor: '#F9F8FC', height: 9}}
                    />
                </div>
                <div className="modal-info-total">
                    <span>Total price: </span>
                    <p>{totalPrice}&nbsp;</p>
                </div>
                <div className="modal-info-error text-danger">{error}</div>
            </div>
            <div className="modal-bottom">
                <button className="cbtn" onClick={mintTickets}
                        disabled={isLotteryEnded(props.lottery) || disabledButton}>
                    <div className="cbtn-inner cbtn--blue cbtn--large">
                        <div className="cbtn-bg"></div>
                        <div className="cbtn-content">
                            <p>Mint</p>
                        </div>
                    </div>
                </button>
            </div>
        </Modal>
    );

}

class Notifier extends React.Component {

    componentDidMount() {
        this.props.onShown();
    }

    render() {
        return null;
    }

}
