import React from "react";
import PurchaseDialog from "./PurchaseDialog"
import {
    costPerTicket,
    getLotteryImageNumber,
    isLotteryEnded,
    isLotteryEndsSoon, maxNumberOfTickets, numberOfTicketsMinted,
    prizePool,
    timeLeft,
} from "../utils/lottery";

export default function ListOfLotteries(props) {
    const {contract, tokenName} = {...props};

    const [purchaseDialogLottery, setPurchaseDialogLottery] = React.useState(null);

    function ticketsLeft(lottery) {
        return maxNumberOfTickets(lottery) - numberOfTicketsMinted(lottery);
    }

    function isMyLottery(lottery) {
        return lottery.players.map(x => x.toLowerCase()).indexOf(contract.selectedAddress) > -1
    }

    function isLotteryButtonDisabled(lottery) {
        return isLotteryEnded(lottery) || (!contract.selectedAddress) || ticketsLeft(lottery) === 0
    }

    function openDialog(lottery) {
        if (!(isLotteryButtonDisabled(lottery))) setPurchaseDialogLottery(lottery)
    }

    return (
        <div className="lotteries">
            <div className="inner">
                <div className="lotteries-inner section">
                    <div className="subtitle">
                        <div className="subtitle-ico is-gold">
                            <img src="/svg/lottery.svg" alt="Select a lottery"/>
                        </div>
                        <span>Select a box</span>
                    </div>
                    <div className="lotteries-list">
                        {(contract.activeLotteries.length === 0) &&
                            <div className={"no-more-lotteries"}>No more boxes. <br/>Please wait for the next launch
                            </div>
                        }
                        {contract.activeLotteries.map((lottery) => (
                            <div key={lottery.base.lotteryId}
                                 className={"lottery " + (isMyLottery(lottery) ? 'is-my' : '')}>
                                <div className="lottery-inner">
                                    <div className="lottery-title">Box #{lottery.base.lotteryId.toString()}</div>
                                    <div className={"lottery-time " + (isLotteryEndsSoon(lottery) ? 'is-soon' : '')}>
                                        <p>Open time:</p>
                                        {isLotteryEndsSoon(lottery) &&
                                            <img src="/svg/fire.svg" alt="Open time"/>
                                        }
                                        <span>{timeLeft(lottery)}</span>
                                    </div>
                                    <div className="lottery-image">
                                        <picture className="img-responsive">
                                            <source srcSet={getLotteryImageNumber(lottery, '.webp')} type="image/webp"
                                                    alt="Lottery"/>
                                            <img src={getLotteryImageNumber(lottery, '.png')} alt="Lottery"
                                                 className="img-responsive"/>
                                        </picture>
                                    </div>
                                    <div className="lottery-prize">
                                        <span>Prize</span>
                                        <p>{prizePool(lottery)} {tokenName}</p>
                                    </div>
                                    <div className="lottery-bottom">
                                        <div className="lottery-bottom-info">
                                            <div className="lottery-bottom-item">
                                                <span>Ticket price</span>
                                                <p>{costPerTicket(lottery)} {tokenName}</p>
                                            </div>
                                            <div className="lottery-bottom-item">
                                                <span>Tickets available</span>
                                                <p>{ticketsLeft(lottery)}/{maxNumberOfTickets(lottery)}</p>
                                            </div>
                                            {isMyLottery(lottery) &&
                                                <div className="lottery-bottom-item is-my">
                                                    <span>
                                                        Your
                                                        <img src="/svg/lottery-white.svg" alt="My tickets"/>
                                                    </span>
                                                    {<p>{lottery.numberOfTicketsMintedByPlayer.toString()}</p>}
                                                </div>
                                            }
                                        </div>
                                        <div className="lottery-bottom-button">
                                            <button className="cbtn" onClick={() => openDialog(lottery)}
                                                    disabled={isLotteryButtonDisabled(lottery)}>
                                                <div className="cbtn-inner cbtn--blue cbtn--large">
                                                    <div className="cbtn-bg"></div>
                                                    <div className="cbtn-content">
                                                        <p>Buy tickets</p>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <PurchaseDialog
                        contract={contract}
                        lottery={purchaseDialogLottery}
                        show={purchaseDialogLottery}
                        onHide={() => setPurchaseDialogLottery(null)}
                        tokenname={props.tokenName}
                        balance={props.balance}
                    />
                </div>
            </div>
        </div>
    );
}

