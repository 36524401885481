import React from "react";

export function NoWalletDetected() {
    function openMetaMaskUrl(url) {
        const a = document.createElement("a");
        a.href = url;
        a.target = "_self";
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    return (
        <div className="overlay-section">
            <div className="overlay-section-inner section">
                <div className="no-wallet">
                    <p className="subtitle"><span>No Ethereum wallet was detected.</span></p>
                    <p className="no-wallet-text">
                        Please install{" "}
                        <a
                            href="http://metamask.io"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            MetaMask
                        </a>
                        .
                    </p>
                    <button className="cbtn" onClick={() => openMetaMaskUrl('https://metamask.app.link/dapp/game.thememebox.io')}>
                        <div className="cbtn-inner cbtn--blue cbtn--medium">
                            <div className="cbtn-bg"></div>
                            <div className="cbtn-content">
                                <p>Open Metamask Mobile</p>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}
