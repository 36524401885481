import React from "react";

export function NetworkErrorMessage({message, dismiss}) {
    async function addNetwork() {
        try {
            const result = await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                    chainId: '0xA4B1',
                    chainName: 'Arbitrum One',
                    nativeCurrency: {
                        name: 'ETH',
                        symbol: 'ETH',
                        decimals: 18
                    },
                    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
                    blockExplorerUrls: ['https://arbiscan.io/']
                }]
            });
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="network-error" role="alert">
            <div className="inner">
                <div className="network-error-inner section-danger">
                    {message}
                    <br/>
                    <button type="button" className="btn-metamask" onClick={addNetwork}>
                        <img className="mr-1" width="15" src="/svg/metamask.svg" alt="Metamask"/>
                        Add Arbitrum One Network
                    </button>
                    <button
                        type="button"
                        className="btn-close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={dismiss}
                    >
                    </button>
                </div>
            </div>
        </div>
    );
}
