import Web3 from "web3";

export function getLotteryImageNumber(lottery, path) {
    let _id
    switch (toEther(lottery.base.prizePool)) {
        case "0.025":
        case "0.00032":
            _id = 5;
            break
        case "0.005":
        case "0.00016":
            _id = 4;
            break
        case "0.003":
        case "0.00008":
            _id = 3;
            break
        case "0.0025":
        case "0.00004":
            _id = 2;
            break
        case "0.0015":
        case "0.001":
        case "0.00002":
        case "0.00001":
            _id = 1;
            break
        default: _id = 1;
    }
    return `/images/box-${_id}${path}`
}

export function toEther(value) {
    const web3 = new Web3(window.ethereum);
    return web3.utils.fromWei(value.toString(), 'ether');
}

export function timeLeft(lottery) {
    const endTime = lottery.base.endingTimestamp.toNumber();
    const now = Math.floor(Date.now() / 1000);
    const seconds = Math.max(endTime - now, 0);
    return new Date(seconds * 1000).toISOString().substring(11, 19);
}

export function isLotteryEndsSoon(lottery) {
    const endTime = lottery.base.endingTimestamp.toNumber();
    const now = Math.floor(Date.now() / 1000);
    const seconds = Math.max(endTime - now, 0);
    return seconds <= 60
}
export function isLotteryEnded(lottery) {
    const endTime = lottery.base.endingTimestamp.toNumber();
    const now = Math.floor(Date.now() / 1000);
    const seconds = Math.max(endTime - now, 0);
    return seconds <= 0
}

export function prizePool(lottery) {
    return toEther(lottery.base.prizePool);
}

export function costPerTicket(lottery) {
    return toEther(lottery.base.costPerTicket);
}

export function maxNumberOfTickets(lottery) {
    return lottery.base.maxNumberOfTickets.toNumber();
}

export function numberOfTicketsMinted(lottery) {
    return lottery.numberOfTicketsMinted.toNumber();
}
