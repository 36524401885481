import React from "react";
import {getLotteryImageNumber, prizePool, timeLeft, toEther} from "../utils/lottery";

export default function ListOfMyLotteries(props) {
    const {contract, tokenName} = {...props};

    const LotteryStatus = {
        NotStarted: 0,
        Open: 1,
        Closed: 2,
        Claimed: 3,
        Canceled: 4
    };


    async function _claimReward(lottery) {
        const lotteryId = lottery.base.lotteryId.toString();
        await contract.contract.withdraw(lotteryId);
    };

    function claimReward(lottery) {
        const lotteryId = lottery.base.lotteryId.toString();
        console.log('Claim reward for lottery #' + lotteryId);
        _claimReward(lottery);
    }

    return (
        <div className="my-lotteries">
            <div className="inner">
                <div className="subtitle">
                    <div className="subtitle-ico">
                        <img src="/svg/prize.svg" alt="My lotteries"/>
                    </div>
                    <span>My boxes</span>
                </div>
                <div className="my-lotteries-inner">
                    <ul className="ml-list">
                        <li className="ml-list-top">
                            <div className="ml-list-name">Box</div>
                            <div className="ml-list-prize">Prize pool</div>
                            <div className="ml-list-number">Tickets</div>
                            <div className="ml-list-time">Time left</div>
                            <div className="ml-list-result">Result</div>
                        </li>
                        {contract.myLotteries.map((lottery) => (
                            <li key={lottery.base.lotteryId} className="ml-list-item section">
                                <div className="ml-list-name">
                                    <div className="ml-list-name-image">
                                        <picture className="img-responsive">
                                            <source srcSet={getLotteryImageNumber(lottery, '.webp')} type="image/webp"
                                                    alt="Lottery"/>
                                            <img src={getLotteryImageNumber(lottery, '.png')} alt="Lottery"
                                                 className="img-responsive"/>
                                        </picture>
                                    </div>
                                    <span>Box #{lottery.base.lotteryId.toString()}</span>
                                </div>
                                <div className="ml-list-prize">
                                    {prizePool(lottery)} {tokenName}
                                </div>
                                <div
                                    className="ml-list-number">{lottery.numberOfTicketsMintedByPlayer.toString()}/{lottery.base.maxNumberOfTickets.toString()}
                                </div>
                                <div className="ml-list-time">{timeLeft(lottery)}</div>
                                <div className="ml-list-result">
                                    {(() => {
                                        if (lottery.base.lotteryStatus === LotteryStatus.NotStarted) {
                                            return (<div className="ml-list-status"><span>Wait for the start</span>
                                            </div>);
                                        } else if (lottery.base.lotteryStatus === LotteryStatus.Open) {
                                            return (
                                                <div className="ml-list-status"><span>Magic Happens...</span></div>);
                                        } else if (lottery.base.lotteryStatus === LotteryStatus.Closed ||
                                            lottery.base.lotteryStatus === LotteryStatus.Claimed) {
                                            if (toEther(lottery.pendingWithdrawal) > 0) {
                                                return (
                                                    <div className="ml-list-claim">
                                                        <div className="ml-list-status is-win">
                                                            <span>You win!</span>
                                                            <img src="/svg/party.svg" alt="You win!"/>
                                                        </div>
                                                        <button className="cbtn" onClick={() => claimReward(lottery)}>
                                                            <div className="cbtn-inner cbtn--purple cbtn--small">
                                                                <div className="cbtn-bg"></div>
                                                                <div className="cbtn-content">
                                                                    <p>Claim {toEther(lottery.pendingWithdrawal)} {tokenName}</p>
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                );
                                            } else {
                                                if (lottery?.winningTicket?.playerAddress?.toLowerCase() === props?.contract?.selectedAddress?.toLowerCase() && props?.contract?.selectedAddress) {
                                                    return (
                                                        <div className="ml-list-status is-win">
                                                            <span>You win. Prize claimed!</span>
                                                            <img src="/svg/party.svg" alt="You win!"/>
                                                        </div>
                                                    );
                                                } else {
                                                    return (
                                                        <div className="ml-list-status"><span>Better luck next time</span></div>);
                                                }
                                            }
                                        }
                                    })()}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

