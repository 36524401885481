import React from "react";

// We'll use ethers to interact with the Ethereum network and our contract
import {ethers} from "ethers";

// We import the contract's artifacts and address here, as we are going to be
// using them with ethers
import LotteryArtifact from "../contracts/Lottery.json";
import contractAddress from "../contracts/contract-address.json";

// All the logic of this dapp is contained in the Dapp component.
// These other components are just presentational ones: they don't have any
// logic. They just render HTML.
import {NoWalletDetected} from "./NoWalletDetected";
import {Loading} from "./Loading";

import ListOfLotteries from "./ListOfLotteries";
import ListOfMyLotteries from "./ListOfMyLotteries";

import Header from "./Header";
import {NetworkErrorMessage} from "./NetworkErrorMessage";

// This is the Hardhat Network id, you might change it in the hardhat.config.js.
// If you are using MetaMask, be sure to change the Network id to 31337.
// Here's a list of network ids https://docs.metamask.io/guide/ethereum-provider.html#properties
// to use when deploying to other networks.
const HARDHAT_NETWORK_ID = process.env.REACT_APP_NETWORK_ID || "42161";
const HARDHAT_NETWORK_NAME = process.env.REACT_APP_NETWORK_NAME || "Arbitrum One";
const CURRENCY_SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL || 'ETH'
console.log("NETWORK_ID: ", HARDHAT_NETWORK_ID)

// This component is in charge of doing these things:
//   1. It connects to the user's wallet
//   2. Initializes ethers and the Token contract
//   3. Polls the user balance to keep it updated.
//   4. Transfers tokens by sending transactions
//   5. Renders the whole application
//
// Note that (3) and (4) are specific of this sample application, but they show
// you how to keep your Dapp and contract's state in sync,  and how to send a
// transaction.
export class Dapp extends React.Component {
    constructor(props) {
        super(props);

        // We store multiple things in Dapp's state.
        // You don't need to follow this pattern, but it's an useful example.
        this.initialState = {
            // The user's address and balance
            selectedAddress: undefined,
            balance: undefined,
            // The ID about transactions being sent, and any possible error with them
            txBeingSent: undefined,
            transactionError: undefined,
            networkError: undefined,
            contract: {},
            loading: false,
            tokenName: CURRENCY_SYMBOL,
            netVersion: undefined,
            loaded: false,
        };

        this.state = this.initialState;
    }


    render() {
        const {contract, selectedAddress} = {
            ...this.state,
        };
        return (
            <>
                {window.ethereum === undefined &&
                    <NoWalletDetected/>
                }
                <Header
                    state={this.state}
                    connectWallet={() => this._connectWallet()}
                    disconnectWallet={() => this._disconnectWallet()}
                />
                {this.state.networkError && (
                    <NetworkErrorMessage
                        message={this.state.networkError}
                        dismiss={() => this._dismissNetworkError()}
                    />
                )}
                {!this.state.loaded && (
                    <Loading/>
                )}
                {(this.state.contract.owner) &&
                    <ListOfLotteries
                        selectedAddress={selectedAddress}
                        contract={contract}
                        tokenName={this.state.tokenName}
                        balance={this.state.balance}
                    />
                }
                {(this.state.selectedAddress && this.state.contract.owner) &&
                    <ListOfMyLotteries
                        selectedAddress={selectedAddress}
                        contract={contract}
                        tokenName={this.state.tokenName}
                    />
                }

                {/*<Footer */}
                {/*  contractAddress={contract.address}*/}
                {/*  owner={contract.owner}*/}
                {/*  balance={this.state.balance.toString()}*/}
                {/*  myAddress={this.state.selectedAddress}*/}
                {/*/>*/}
            </>
        );

    }

    componentDidMount() {
        if (window.ethereum !== undefined) {
            this._getNetVersion()
            this.addListeners()
        } else {
            this.setState({
                loaded: true
            })
        }
    }

    componentWillUnmount() {
        // We poll the user's balance, so we have to stop doing that when Dapp
        // gets unmounted
        this._stopPollingData();
    }

    addListeners() {
        if (window.ethereum !== undefined) {
            // We reinitialize it whenever the user changes their account.
            window.ethereum.on("accountsChanged", ([newAddress]) => {
                this._stopPollingData();
                // `accountsChanged` event can be triggered with an undefined newAddress.
                // This happens when the user removes the Dapp from the "Connected
                // list of sites allowed access to your addresses" (Metamask > Settings > Connections)
                // To avoid errors, we reset the dapp state
                if (newAddress === undefined) {
                    return this._resetState();
                }

                this._initialize(newAddress);
                this._fetchData();
            });


            // We reset the dapp state if the network is changed
            window.ethereum.on("chainChanged", async ([networkId]) => {
                this._stopPollingData();
                this._resetState();

                const version = await window.ethereum.request({method: 'net_version'})
                if (version) {
                    this.setState({
                        netVersion: version,
                    })

                    setTimeout(() => {
                        this._stopPollingData();
                        this._resetState();
                        this._fetchData()
                    }, 50)
                }

            });
        }
    }

    async _getNetVersion() {
        if (window.ethereum !== undefined) {
            const version = await window.ethereum.request({method: 'net_version'})
            if (version) {
                this.setState({
                    netVersion: version,
                    loaded: true,
                })

                const accounts = await window.ethereum.request({method: 'eth_accounts'});
                if (accounts.length) {
                    this._fetchData()
                    this._connectWallet()

                } else {
                    this.setState({
                        networkError: `Please unlock your Metamask`,
                    });
                }
            }
        }
    }

    async _connectWallet() {

        // First we check the network
        if (!this._checkNetwork()) {
            return;
        }

        // This method is run when the user clicks the Connect. It connects the
        // dapp to the user's wallet, and initializes it.

        this._setLoading(true)

        // To connect to the user's wallet, we have to run this method.
        // It returns a promise that will resolve to the user's address.
        const [selectedAddress] = await window.ethereum.request({
            method: "eth_requestAccounts",
        });


        // Once we have the address, we can initialize the application.

        this._initialize(selectedAddress);

        this._stopPollingData();

        this.setState({
            networkError: undefined
        })

        this._fetchData();

    }

    _disconnectWallet() {
        this._setLoading(true)
        this.setState({
            selectedAddress: undefined,
            balance: undefined,
        });
        setTimeout(() => {
            this._setLoading(false)
        }, 1000)
    }

    _initialize(userAddress) {
        // This method initializes the dapp

        // We first store the user's address in the component's state
        this.setState({
            selectedAddress: userAddress,
        });
        this._setLoading(false)
    }

    async _fetchData() {
        // Then start polling lottery info
        // Fetching the token data and the user's balance are specific to this
        // sample project, but you can reuse the same initialization pattern.
        if (this._checkNetwork()) {
            this._initializeEthers();
            this._startPollingData();
        }
    }

    async _initializeEthers() {
        // We first initialize ethers by creating a provider using window.ethereum
        this._provider = new ethers.providers.Web3Provider(window.ethereum);

        // Then, we initialize the contract using that provider and the token's
        // artifact. You can do this same thing with your contracts.
        this._contract = new ethers.Contract(
            contractAddress.Lottery,
            LotteryArtifact.abi,
            this._provider.getSigner(0)
        )
    }

    // The next two methods are needed to start and stop polling data. While
    // the data being polled here is specific to this example, you can use this
    // pattern to read any data from your contracts.
    //
    // Note that if you don't need it to update in near real time, you probably
    // don't need to poll it. If that's the case, you can just fetch it when you
    // initialize the app, as we do with the token data.
    _startPollingData() {
        this._pollDataInterval = setInterval(() => this._updateInfo(), 1000);
        // We run it once immediately so we don't have to wait for it
        this._updateInfo();
    }

    _stopPollingData() {
        clearInterval(this._pollDataInterval);
        this._pollDataInterval = undefined;
    }

    // The next two methods just read from the contract and store the results
    // in the component state.
    async _getTokenData() {
        const name = await this._token.name();
        const symbol = await this._token.symbol();

        this.setState({tokenData: {name, symbol}});
    }

    async _updateInfo() {
        // console.log("_updateInfo");
        if (this._checkNetwork()) {
            this._updateUser();
            this._updateLotteries();
        }
    }

    async _updateUser() {
        // console.log("_updateUser");
        if (this.state.selectedAddress && this._provider) {
            const balance = await this._provider.getBalance(this.state.selectedAddress);
            this.setState({balance});
        }
    }

    async _getActiveLotteries() {
        let lotteries = await this._contract.getActiveLotteriesExtended();
        let sortedLotteries = [...lotteries];
        sortedLotteries.sort((a, b) => b.base.prizePool - a.base.prizePool);
        return sortedLotteries;
    }

    async _getMyLotteries() {
        let lotteries = await this._contract.getMyLotteries();
        let sortedLotteries = [...lotteries];
        sortedLotteries.sort((a, b) => b.base.lotteryId - a.base.lotteryId);
        return sortedLotteries;
    }

    async _updateLotteries() {
        const newState = {
            contract: this._contract,
            activeLotteries: await this._getActiveLotteries(),
            myLotteries: await this._getMyLotteries(),
            address: contractAddress.Lottery,
            owner: await this._contract.owner(),
            selectedAddress: this.state.selectedAddress
        };
        this.setState({
            contract: newState,
        });
    }

    // This method just clears part of the state.
    _dismissTransactionError() {
        this.setState({transactionError: undefined});
    }

    // This method just clears part of the state.
    _dismissNetworkError() {
        this.setState({networkError: undefined});
    }

    // This is an utility method that turns an RPC error into a human readable
    // message.
    _getRpcErrorMessage(error) {
        if (error.data) {
            return error.data.message;
        }

        return error.message;
    }

    // This method resets the state
    _resetState() {
        this.setState({...this.initialState, netVersion: this.state.netVersion, loaded: true});
    }

    _setLoading(status) {
        this.setState({
            loading: status,
        })
    }

    // This method checks if Metamask selected network is Localhost:8545
    _checkNetwork() {
        if (this.state.netVersion === HARDHAT_NETWORK_ID) {
            return true;
        }

        this.setState({
            networkError: `Please connect Metamask to ${HARDHAT_NETWORK_NAME} network (current: ${this.state.netVersion})`,
        });

        return false;
    }

}
