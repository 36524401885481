import React from "react";

export default function Header({disconnectWallet, connectWallet, state}) {
    function getButtonText(address) {
        return address ? getAddressShrink(address) : 'Connect wallet'
    }

    function getAddressShrink(address) {
        if (address) {
            const start = address.slice(0, 5)
            const end = address.slice(-5)
            return start + '...' + end
        } else return '...'
    }

    function onClickButton() {
        if (state.selectedAddress && state.contract) {
            disconnectWallet()
        } else {
            connectWallet()
        }
    }

    return (
        <header className="header">
            <div className="inner">
                <div className="section header-inner">
                    <div className="header-logo">
                        <p>MEMEbox</p><span>Find your future gem</span>
                    </div>
                    <div className="header-button">
                        <button type="button" className="cbtn" onClick={onClickButton} disabled={state.loading}>
                            <div className="cbtn-inner cbtn--blue cbtn--medium">
                                <div className="cbtn-bg"></div>
                                <div className="cbtn-content">
                                    {!state.loading &&
                                    <p>{getButtonText(state.selectedAddress)}</p>
                                    }
                                    {state.loading &&
                                    <div className="spinner-border text-light" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    }
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
}
